import './_components.js';
var swiper = new Swiper(".mySwiper", {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 5000,
  },
});

const serviceSlider = new Swiper('.mySwiper1', {
  slidesPerView: 2.2,
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    1150: {
      slidesPerView: 2.2,
    },
    850: {
      slidesPerView: 1.8,
    },
    320:{

      slidesPerView: 1.4,
    }
  }
})
const waaromSlider = new Swiper('.waarom__swiper', {
  spaceBetween: 30,
  slidesPerView: 3.5,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    912: {
      slidesPerView: 3.5,
    },
    660: {
      slidesPerView: 2.5,
    },
    320: {
      spaceBetween: 10,
      slidesPerView: 1.3,
    },
  }


})


const introSlider = new Swiper('.intro__slider', {
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  }
})
const newsSwiper = new Swiper('.news__swiper', {
  spaceBetween: 40,
  slidesPerView: 2.75,

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    912: {
      slidesPerView: 2.75,
    },
    660: {
      slidesPerView: 1.75,
    },
    320: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },

  }
})
const onzeSwiper = new Swiper('.onze__swiper', {

  speed: 400,
  spaceBetween: 100,


  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const accordionItems = document.querySelectorAll('.accordion__item')
  const accordionContainer = document.querySelector('.accordion')

  accordionItems.forEach((item, index) => {
    const header = item.querySelector('.accordion__header')
    const content = item.querySelector('.accordion__content')
    const icon = item.querySelector('.accordion__icon')

    if (item.hasAttribute('data-open')) {
      item.classList.add('open')
      content.style.height = `${content.scrollHeight}px`
      icon.classList.replace('hide', 'show')
    }
    const colorBackground = item.dataset.colorBack
    const colorBorder = item.dataset.colorBorder

    if (colorBackground || colorBorder) {
      item.style.background = colorBackground
      item.style.borderColor = colorBorder
    }

    header.addEventListener('click', () => {
      item.classList.toggle('open')
      if (item.classList.contains('open')) {
        content.style.height = `${content.scrollHeight}px`
        icon.classList.replace('hide', 'show')
      } else {
        content.style.height = 0
        icon.classList.replace('show', 'hide')
      }

      if (accordionContainer.dataset.autoClosing === 'true') {
        removeOpen(index)
      }
    })
  })

  function removeOpen(i) {
    accordionItems.forEach((item, index) => {
      if (i != index) {
        const content = item.querySelector('.accordion__content')
        const icon = item.querySelector('.accordion__icon')

        item.classList.remove('open')
        content.style.height = 0
        icon.classList.replace('show', 'hide')
      }
    })
  }
})


var swiper = new Swiper(".mySwiperv", {
  direction: "vertical",
  slidesPerView: 1,
  spaceBetween: 30,
  mousewheel: true,
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
});

var swiper = new Swiper(".sipermob", {
  slidesPerView: 1.5,
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// Фикс фулскрин-блоков


import GraphTabs from 'graph-tabs';
const tabs = new GraphTabs('tab');


lightGallery(document.querySelector('.onze__galary'))


let menuBtn = document.querySelector('.menu-btn');
let menu = document.querySelector('.nav');
let menuItem = document.querySelectorAll('.nav__link');

menuBtn.addEventListener('click', function(){
	menuBtn.classList.toggle('active');
	menu.classList.toggle('active');
})


menuItem.forEach (function(menuItem) {
  menuItem.addEventListener('click',function(){
          menuBtn.classList.toggle('active');
          menu.classList.toggle('active');
  })
})


